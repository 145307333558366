import { images } from '../components/assets/images';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, Page, useSnackbar } from 'zmp-ui';

import FireworksEffect from '../components/FireworksEffect ';
import { api, baseUrl, requests } from '../api';
import { AppContext } from '../components/context/MyContext';
import axios from 'axios';
import Swal from 'sweetalert2';

import { IconString } from 'zmp-ui/icon';
import handleCopyLink, {Toast} from '../components/uitilities';
import {CheckWithdrawal} from "../types/types";


interface IGiftProps {
    name: string;
    code: string;
}

const Gift = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { phoneUser } = useContext(AppContext);

    const [isReceived, setIsReceived] = React.useState(false);
    const [phoneRelative, setPhoneRelative] = React.useState<string>("");
    const [imageGift, setImageGift] = React.useState<string>("");
    const [isCheckGiftRelative, setIsCheckGiftRelative] = React.useState<boolean>(false);
    const [isGiftSuccess, setIsGiftSuccess] = React.useState<boolean>(false);
    const [statusGiftUser, setStatusGiftUser] = React.useState<number | null>(null);
    const [checkWithdraw, setCheckWithdraw] = React.useState<CheckWithdrawal>();
    const { name, code } = location.state as IGiftProps;
    const [showBoxGift, setShowBoxGift] = useState(true);
    const phoneRegex = /^[0-9]{10}$/;

    const playSuccessSound = () => {
    };

    const handleReceivedGifts = () => {
        setIsReceived(true);
        playSuccessSound();
    };

    // Create withdraw gift request for a relative
    const createWithdrawGiftRelative = async () => {
        if (!phoneRelative) {
            Toast({ type: 'error', message: 'Vui lòng nhập số điện thoại người thân' });

            return;
        }

        if (!phoneRegex.test(phoneRelative)) {
            Toast({ type: 'error', message: 'Số điện thoại không hợp lệ. Vui lòng nhập số điện thoại có 10 chữ số.' });

            return;
        }

        try {
            const response = await requests({
                url: api.createWithdrawGiftRelative(),
                method: "POST",
                data: {
                    phone: phoneUser,
                    phone_relative: phoneRelative,
                },
            });

            if (response.status === 1) {
                setIsCheckGiftRelative(true);
                Toast({ type: 'success', message: response.msg });

            } else {
                Toast({ type: 'error', message: response.msg });

            }
        } catch (error) {
            console.error("Error creating withdraw gift request:", error);
            Toast({ type: 'error', message: 'Đã xảy ra lỗi khi tạo yêu cầu rút quà' });

        }
    };

    // Withdraw gift for the user
    const withdrawGift = async () => {
        try {
            const response = await requests({
                url: api.withdrawGift(),
                method: "POST",
                data: {
                    type_list_gift_yourself: 1,
                    code_invoice: code,
                },
            });

            console.log(response);
        
            setIsGiftSuccess(true);
            setImageGift(`${baseUrl}${response?.gift?.image}`);
            playSuccessSound();
            setIsReceived(true);
Toast({ type: 'success', message: 'Rút quà thành công' });

        } catch (error) {
            console.error("Error withdrawing gift:", error);
            Toast({ type: 'error', message: 'Đã xảy ra lỗi khi rút quà' });

        }
    };
  
    // // Copy gift link to clipboard
    
    // Check if the user has already withdrawn a gift
    const checkWithdrawGift = async () => {
        try {


            const res = await axios.get(api.checkWithdrawGift(), {
                params: { phone: phoneUser },
            });

            setCheckWithdraw(res.data);

            switch (res.data.status) {
                case 1:
                    setStatusGiftUser(1);
                    break;
                case 2:
                    setStatusGiftUser(2);
                    break;
                case 3:
                    setStatusGiftUser(3);
                    break;
                case 0:
                    setStatusGiftUser(0);
                    setImageGift(`${baseUrl}${res.data.gift.image}`);
                    break;
                default:
                    // Handle unexpected status values if necessary
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    };
    const UpdateIsUserGift = async () => {
        try {
            const result = await Swal.fire({
                title: "Xác nhận quà?",
                text: "Bạn đã nhận từ nhân viên chưa?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Chưa nhận",
                confirmButtonText: "Đã nhận",
            });

            if (result.isConfirmed) {
                try {
                    const res = await requests({
                        url: api.update_is_user_gift(),
                        method: "POST",
                        data: { phone: phoneUser }
                    });

                    checkWithdrawGift();
                    if (res?.data.status === 1) {
                        checkWithdrawGift();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowBoxGift(false);
        }, 3000);
       

        return () => clearTimeout(timer);
        
    }, [imageGift]);



    return (
        <Page className='page bg-main relative  h-full min-h-screen' >
            <div
                className="absolute top-12 left-2 bg-white opacity-80 p-2 rounded-full z-[999]" // Các thuộc tính yêu cầu
                onClick={() => navigate(-1)}
            >
                {/*<Icon*/}
                {/*    icon="zi-arrow-left"*/}
                {/*    className="font-bold"*/}
                {/*    size={30}*/}
                {/*/>*/}
                <div className={''}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"
                         viewBox="0 0 448 512">
                        <path
                            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                    </svg>
                </div>
            </div>


            <img src={images.bg_top_right} className="absolute  top-0 right-0  w-[100px]" alt=""/>

            <div className="absolute top-[50px] z-10 w-full flex justify-center left-0">
                {/* <img src={images.banner_mini_gamer} alt="" className='max-h-[150px]' onClick={() => setIsReceived(false)} /> */}
                <img src={images.banner_mini_gamer} alt="" className='max-h-[150px]'/>
            </div>

            {
            !isReceived && (
                    <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full flex justify-center">
                        {
                            name === 'nguoi-than-rut-qua' ? (
                                <img src={images.qua_nguoi_than} alt="" className='w-[70%]' />
                            ) : name === 'tu-rut-qua' ? (
                                <img src={images.qua_tu_rut} alt="" className='w-[70%]' />
                            ) : null
                        }

                    </div>

                )
            }


            {
                !isReceived && (
                    <div className="absolute bottom-10 w-full left-0 flex justify-center">
                        {
                            name === 'tu-rut-qua' ? (
                                <img src={images.btn_rut_qua} alt="" onClick={withdrawGift} />
                            ) : (
                                <img src={images.btn_gui_nguoi_than} alt="" onClick={handleReceivedGifts} />
                            )
                        }
                    </div>
                )
            }
            {isGiftSuccess && <FireworksEffect trigger={true} />}

            {
                isReceived && name === 'tu-rut-qua' && (
                    <>
                        <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full flex justify-center">
                            {showBoxGift ? (
                                <div className="text-center flex justify-center">
                                    <img src={images.box_gift} alt="" className="shake" onClick={() => handleCopyLink({ phoneRelative, code })} />
                                </div>
                            ) : (
                                <img src={imageGift} alt="" className='w-[70%] animate-zoom-rotate' />
                            )}
                        </div>

                        <div className="absolute bottom-10 w-full left-0 flex justify-center">
                            {
                                checkWithdraw?.type === 1 ? <img src={images.thong_diep} alt="" className=" object-contain" /> :
                                    <img src={images.button_nhan_qua} alt="" onClick={UpdateIsUserGift} />
                            }
                        </div>
                    </>
                )
            }

            {
                isReceived && name === 'nguoi-than-rut-qua' && (
                    <>


                        {
                            isCheckGiftRelative ? (<div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full max-w-md flex justify-center px-5">
                                <div className="bg-pink-600 shadow-lg w-full rounded-lg py-8 px-6 relative">
                                    <p className="text-white text-lg font-bold mb-4 text-center flex items-center justify-center">
                                        <i className="fas fa-link mr-2"></i> {/* Icon cho link */}
                                        🎁Link gửi người thân rút quà🎁
                                    </p>
                                    <div className="flex justify-center mt-4">
                                        <div
                                            className="py-3 px-4 rounded-lg text-white text-center cursor-pointer text-lg font-semibold bg-gradient-to-r from-yellow-400 to-orange-500 hover:from-orange-500 hover:to-yellow-400 transition-all"
                                            onClick={() => handleCopyLink({ phoneRelative, code })}
                                        >
                                            <i className="fas fa-copy mr-2"></i> Sao chép link🧧
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ) : (
                                <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full flex justify-center">
                                    <div className="relative w-[90%] flex justify-center">
                                        <img src={images.bg_form_sdt_nguoi_than} alt="" className='w-full' />
                                        <div className="absolute top-[60%] left-0 flex flex-col justify-center items-center w-full">
                                            <input type="text" className='w-[80%] h-[40px] rounded ring-amber-600 p-2 text-xl focus-visible:border-amber-600' onChange={(e) => setPhoneRelative(e.target.value)} />
                                            <img src={images.btb_gui} alt="" className='mt-3' onClick={createWithdrawGiftRelative} />
                                        </div>
                                    </div>
                                </div>
                            )
                        }


                    </>
                )
            }


        </Page>
    )

}
export default Gift;