import { images } from '../components/assets/images';
import React, {  useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Page, useSnackbar, Sheet as ZMPSheet } from 'zmp-ui';
import FireworksEffect from '../components/FireworksEffect ';
import { api, app_id, baseUrl, IdOa, requests, secret_key } from "../api";
import { AppContext } from '../components/context/MyContext';
import axios from 'axios';
import {Toast} from "../components/uitilities";

const GiftRelative = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { openSnackbar } = useSnackbar();

    const [isReceived, setIsReceived] = React.useState(false);
    const [imageGift, setImageGift] = React.useState<string>("");

    const phone = searchParams.get("phone");
    const code = searchParams.get("code");

    const [showFollow, setShowFollow] = useState<boolean>(false);
    const { user, phoneUser, setPhoneUser, setUser } = React.useContext(AppContext);
    const [showPhone, setShowPhone] = useState(false);
    const [loading, setLoading] = useState(true);

    const [showBoxGift, setShowBoxGift] = useState(true);

    // Function to play success sound
    const playSuccessSound = () => {
    };



    // Function to handle gift withdrawal
    const withdrawGift = async () => {



        if (phone !== phoneUser) {
            Toast({ type: 'error', message: 'Số điện thoại không khớp với người gửi quà' });

            return;
        }
        console.log("code", code);


        try {
            const response = await requests({
                url: api.withdrawGift(),
                method: "POST",
                data: {
                    code_invoice: code,
                },
            });
            console.log("response", response);
            setImageGift(`${baseUrl}${response?.gift?.image}`);
            playSuccessSound();
            setIsReceived(true);
Toast({ type: 'success', message: 'Rút quà thành công' });

        } catch (error) {
            console.error("Error withdrawing gift:", error);
            Toast({ type: 'error', message: 'Đã xảy ra lỗi khi rút quà' });

        }
    };






    // Follow OA API call
    const followOARequest = async () => {
        try {
            setLoading(true);
            const res = await axios.post(api.followOA(), {
                phone: phoneUser,
                type: '1',
                app_id,
            });
            if (res.data.status) {
                Toast({ type: 'success', message: 'Theo dõi thành công' });

                setUser({ ...user, followedOA: true });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }






    useEffect(() => {
        const timer = setTimeout(() => {
            setShowBoxGift(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [showBoxGift]);



    return (
        <Page className='page bg-main relative  h-full min-h-screen'>
            <img src={images.bg_bottom_left} className="absolute bottom-0 left-0 w-[100px]" alt="" />
            <img src={images.bg_top_right} className="absolute  top-0 right-0  w-[100px]" alt="" />

            <div className="absolute top-[50px] z-10 w-full flex justify-center left-0">
                {/* <img src={images.banner_mini_gamer} alt="" className='max-h-[150px]' onClick={() => setIsReceived(false)} /> */}
                <img src={images.banner_mini_gamer} alt="" className='max-h-[150px]' />
            </div>

            {
                !isReceived && (
                    <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full flex justify-center">
                        <img src={images.qua_nguoi_than} alt="" className='w-[70%]' />
                    </div>

                )
            }

            {
                !isReceived && (
                    <div className="absolute bottom-10 w-full left-0 flex justify-center">
                        <img src={images.btn_rut_qua} alt="" onClick={withdrawGift} />
                    </div>
                )
            }
            {isReceived && <FireworksEffect trigger={true} />}

            {
                isReceived && (
                    <>
                        <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full flex justify-center">
                            {showBoxGift ? (
                                <div className="text-center flex justify-center">
                                    <img src={images.box_gift} alt="" className="shake" />
                                </div>
                            ) : (
                                <img src={imageGift} alt="" className='w-[70%] animate-zoom-rotate' />
                            )}
                        </div>
                        <div className="absolute bottom-10 w-full left-0 flex justify-center">
                            <img src={images.button_nhan_qua} alt="" />
                        </div>
                    </>
                )
            }





        </Page>
    )

}
export default GiftRelative;