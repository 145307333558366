import React, { createContext, useState, useEffect } from 'react';
import {IUser} from "../model/types/type";



interface AppContextType {
    user: IUser;
    phoneUser: string | null;
    setPhoneUser: React.Dispatch<React.SetStateAction<string | null>>;
    setUser: React.Dispatch<React.SetStateAction<IUser>>;
  }
  const initialAppContext: AppContextType = {
    user: {
      id: "",
      avatar: "",
      name: "",
      idByOA: "",
      isSensitive: false,
      followedOA: false,
    },
    phoneUser: "",
    setPhoneUser: () => null,
    setUser: () => null,
  };
// Tạo context với giá trị mặc định
// Tạo provider component
export const AppContext = createContext<AppContextType>(initialAppContext);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<IUser>(initialAppContext.user);
  const [phoneUser, setPhoneUser] = useState<string|null>("");
  const getUser = async () => {
    try {
        const user = localStorage.getItem("user");
        if (user) {
            setUser(JSON.parse(user));
        }
        } catch (error) {
            console.log(error);
        }
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <AppContext.Provider value={{ user, phoneUser, setPhoneUser, setUser }}>
      {children}
    </AppContext.Provider>
  );
};