import React, {Suspense, useEffect, useState} from "react";
import {List, Page, Icon, useSnackbar, Sheet as ZMPSheet} from "zmp-ui";
import {images} from "../components/assets/images";
import FormCode from "../components/form_code";
import WithdrawGift from "../components/withdrawGift";
import {AppContext} from "../components/context/MyContext";

import axios from "axios";
import {api, app_id, baseUrl, IdOa, requests, secret_key} from "../api";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {CheckWithdrawal} from "../types/types";
import Swal from "sweetalert2";
import handleCopyLink, {Toast} from "../components/uitilities";

const HomePage: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [is_checked_code, setIsCheckedCode] = React.useState(false);
    const [code, setCode] = React.useState<string>("");
    const [showPhone, setShowPhone] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showFollow, setShowFollow] = useState<boolean>(false);
    const {user, phoneUser, setPhoneUser, setUser} = React.useContext(AppContext);
    const [statusGiftUser, setStatusGiftUser] = React.useState<number | null>(null);
    const [imageGift, setImageGift] = React.useState<string>("");
    const [checkWithdraw, setCheckWithdraw] = React.useState<CheckWithdrawal>();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const phone = searchParams.get("phone");
    const handleDrawGifts = (name: string) => {
        navigate("/gift", {state: {name, code}});
    };

    useEffect(() => {
        console.log("phone", phone);
        if (phone) {
            setPhoneUser(phone);
        }
    }, [phone]);




    const handleCheckCode = async (code: string) => {
        console.log("code", code);
        if (!code) {
            Toast({type: 'error', message: 'Vui lòng nhập mã hóa đơn'});

            return;
        }

        try {
          if (phone){
              const response = await axios.get(api.checkInvoice(), {
                  params: {
                      code_invoice: code,
                      phone: phone,
                  },
              });
              console.log(response.data);
              if (response.data.data !== 1) {
                  Toast({type: 'error', message: response.data.message});
                  checkWithdrawGift();
              } else {
                  setCode(code);
                  setIsCheckedCode(true);
              }
          }

        } catch (error) {
            console.error("Error checking invoice code:", error);
            Toast({type: 'error', message: 'Đã xảy ra lỗi khi kiểm tra mã hóa đơn'});

        }
    };


    // Check if the user has already withdrawn a gift
    const checkWithdrawGift = async () => {
        try {
            console.log(phone);
            const res = await axios.get(api.checkWithdrawGift(), {
                params: {phone: phone},
            });
            console.log(res.data);
            setCheckWithdraw(res.data);

            switch (res.data.status) {
                case 1:
                    setStatusGiftUser(1);
                    break;
                case 2:
                    setStatusGiftUser(2);
                    break;
                case 3:
                    setStatusGiftUser(3);
                    setCode(res.data.info_withdraw.code_invoice_kiotviet);
                    setIsCheckedCode(true);
                    break;
                case 0:
                    setStatusGiftUser(0);
                    setImageGift(`${baseUrl}${res.data.gift.image}`);
                    break;
                default:
                    // Handle unexpected status values if necessary
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        checkWithdrawGift();
    }, [phoneUser,phone]);

    useEffect(() => {
        if (user?.followedOA === false && !!phone) {
            setShowFollow(true);
        }
    }, [phoneUser, user?.followedOA,phone]);


    const checkIsUserGift = async () => {
        try {
            const result = await Swal.fire({
                title: "Xác nhận quà?",
                text: "Bạn đã nhận từ nhân viên chưa?",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Chưa nhận",
                confirmButtonText: "Đã nhận",
            });

            if (result.isConfirmed) {
                try {
                    const res = await requests({
                        url: api.update_is_user_gift(),
                        method: "POST",
                        data: {phone: phone}
                    });

                    checkWithdrawGift();
                    if (res?.data.status === 1) {
                        checkWithdrawGift();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        console.log(statusGiftUser)
    }, [statusGiftUser]);
    return (
        <Page className="page bg-main relative  h-full min-h-screen">
            {/* bottom left */}

            <img src={images.bg_bottom_left} className="absolute  bottom-0 left-0 w-[100px]" alt=""/>
            <img src={images.bg_bottom_right} className="absolute  bottom-0 right-0  w-[100px]" alt=""/>
            <img src={images.bg_top_right} className="absolute  top-0 right-0  w-[100px]" alt=""/>

            <div className="absolute top-[50px] z-10 w-full flex justify-center left-0">
                <img src={images.banner_mini_gamer} alt="" className="w-[85%]"/>
            </div>

            {
                statusGiftUser === 1 && (
                    is_checked_code ?
                        <WithdrawGift handleDrawGifts={handleDrawGifts}/> :
                        <FormCode handleCheckCode={handleCheckCode}/>
                )
            }
            {statusGiftUser === null && (
                <FormCode handleCheckCode={handleCheckCode}/>
            )}
            {
                statusGiftUser === 3 && (
                    is_checked_code &&
                    <WithdrawGift handleDrawGifts={handleDrawGifts}/>
                )
            }
            {
                statusGiftUser === 2 && (
                    <>
                        <>
                            <div className="absolute top-[50%] left-0 w-full -translate-y-[50%] overflow-auto p-4">
                                <div className="text-center flex justify-center">
                                    <img
                                        src={images.box_gift}
                                        alt=""
                                        className="shake"
                                        onClick={() => handleCopyLink({
                                            phoneRelative: checkWithdraw?.info_withdraw_relative.phone || '',
                                            code: checkWithdraw?.info_withdraw.code_invoice_kiotviet || ''
                                        })}
                                    /></div>
                                <div className="flex justify-center">
                                    <p className="element" style={{fontFamily: 'SVN-KongaPro'}}>Nhấn vào hộp quà để sao chép
                                        link gửi cho người thân</p>
                                </div>
                                <p className="text-center element text-4xl w-full font-semibold"
                                   style={{fontFamily: 'SVN-KongaPro'}}>
                                    Đang chờ người thân
                                    <br/>
                                    của bạn rút quà...</p>

                            </div>

                            <div className="absolute bottom-6 left-0 w-full flex justify-center flex-col">
                                {/* <div className="flex justify-center mb-2">
                  <div
                    className="py-3 px-14 rounded text-white text-center cursor-pointer   font-bold text-xl"
                    style={{
                      background: 'linear-gradient(357.61deg, #FC670A 1.94%, #FFF16A 94.52%)',
                      fontFamily: 'SVN-KongaPro',
                    }}
                    onClick={handleCopyLink}
                  >
                    Sao chép link
                  </div>
                </div> */}
                                <div className="flex justify-center">
                                    <img
                                        src={images.btn_lam_moi}
                                        alt=""
                                        className="object-contain w-[290px]"  // Điều chỉnh chiều rộng thành 290px
                                        onClick={checkWithdrawGift}
                                    />
                                </div>

                            </div>

                        </>


                        {/* {
              checkWithdraw?.info_withdraw.type === 0 && checkWithdraw.info_withdraw_relative && (
                <div className="">
                  <div className="absolute top-[55%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-full flex justify-center px-5">
                    <div className="bg-[#C70D52] bg-opacity-35 w-full rounded py-10 px-5">
                      <p className='text-blue-950 text-xl font-semibold underline'>Link gửi cho người thân</p>
                      <div className="flex justify-center mt-5">
                        <div
                          className="py-3 p-2 rounded text-white text-center cursor-pointer text-xl font-semibold"
                          style={{
                            background: 'linear-gradient(357.61deg, #FC670A 1.94%, #FFF16A 94.52%)'
                          }}
                          onClick={handleCopyLink}
                        >
                          Sao chép link
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              )
            } */}

                    </>
                )
            }
            {
                statusGiftUser === 0 && (
                    <>
                        <div className="absolute top-[55%] left-0 w-full -translate-y-[50%] overflow-auto p-4">
                            <div className="text-center flex justify-center">
                                <img src={imageGift} alt="" className="h-[450px]"/>

                            </div>

                        </div>

                        {
                            checkWithdraw?.type === 1 && (
                                <div className="absolute bottom-3 left-0 w-full flex justify-center ">
                                    <img src={images.thong_diep} alt="" className=" bject-contain"/>
                                </div>
                            )
                        }
                        {
                            checkWithdraw?.type === 0 && (
                                <div className="absolute bottom-3 left-0 w-full flex justify-center ">
                                    <img src={images.button_nhan_qua} alt="" className=" bject-contain"
                                         onClick={checkIsUserGift}/>
                                </div>
                            )
                        }


                    </>
                )
            }

        </Page>


    );
};

export default HomePage;
