import { api, requests } from "../api";
import Swal from "sweetalert2";

interface HandleCopyLinkProps {
    phoneRelative: string;
    code: string;
}


interface getBaseUrlLink {
    status: number;
    baseUrl_Zalo_App: string;

}

const fetchBaseUrlLink = async () => {
    try {
        const get_base_url_link: getBaseUrlLink = await requests({
            url: api.get_base_url_link(),
            method: "GET",
            data: {},
        });
        return get_base_url_link.baseUrl_Zalo_App;
    } catch (error) {
        console.error('Failed to fetch base URL link:', error);
    }
};

// Call the function

const baseUrl_Zalo_App = await fetchBaseUrlLink();

const handleCopyLink = ({ phoneRelative, code }: HandleCopyLinkProps) => {
    try {

        // const link = `${baseUrl_Zalo_App}/gift-relatives/?code=${code}&phone=${phoneRelative}`;
        const link = `${ window.location.origin}/gift-relatives/?code=${code}&phone=${phoneRelative}`;
        navigator.clipboard.writeText(link);
    } catch (error) {

    }
};

interface  ToastProps {
    type: string;
    message: string;
}
export  const Toast = ({ type, message }:ToastProps) => {
    const toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        }
    });

    if (type === 'error') {
        toast.fire({
            icon: 'error',
            title: message
        });
    } else if (type === 'success') {
        toast.fire({
            icon: 'success',
            title: message
        });
    } else if (type === 'warning') {
        toast.fire({
            icon: 'warning',
            title: message
        });
    }
};


export default handleCopyLink;