import React from 'react';

import './css/tailwind.css';
import './css/global.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {routes} from "./components/routes/routes";
function App() {
  return (
    <div className="">
     <div className="max-w-[455px] m-auto">
         <Router>
             <Routes>
                 {
                     routes.map((route, index) => {
                             return (
                                 <Route key={index} path={route.path} element={<route.element />}></Route>
                             );
                         }
                     )}
             </Routes>
         </Router>
     </div>
    </div>
  );
}

export default App;
